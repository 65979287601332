// Initialize the GlobalNav class
const globalNavElement = document.getElementById('global-nav');
if (globalNavElement) {
  new GlobalNav(globalNavElement);
}
class GlobalNav {
  constructor(globalNavElement) {
    this.globalNavElement = globalNavElement;
    this.mainNavHeightXl = 64;
    this.mainNavHeight = 60;
    this.level2MenuButtons = [];
    this.ICON_LIST = 'list';
    this.ICON_SEARCH = 'search';
    this.ARIA_LABEL_CLOSE_MENU = 'Closes site menu options.';
    this.ARIA_LABEL_OPEN_MENU = 'Opens site menu options.';
    this.ARIA_LABEL_CLOSE_SEARCH = 'Close search panel.';
    this.ARIA_LABEL_OPEN_SEARCH = 'Opens search panel.';
    this.searchOffcanvas = document.getElementById('search-offcanvas');
    this.searchOffcanvasCloseBtn = this.searchOffcanvas.querySelector('button.search-close-btn');
    this.searchLearningLevelDropdownButton = this.searchOffcanvas.querySelector(
      '.searchLearningLevelDropdown button span'
    );
    this.searchLearningLevelDropdownChoices = this.searchOffcanvas.querySelectorAll(
      '.searchLearningLevelDropdownItems .dropdown-item'
    );
    this.mainNavOffCanvas = document.getElementById('main-nav');
    this.menuButton = globalNavElement.querySelector('.navbar-toggle');
    this.searchMenuButton = globalNavElement.querySelector('.search-offcanvas-button');
    this.backdrop = globalNavElement.querySelector('.menu-page-backdrop');
    this.level2Menus = globalNavElement.querySelectorAll('.level2Menu');
    this.level3Menus = globalNavElement.querySelectorAll('.level3Menu');
    this.backBtns = globalNavElement.querySelectorAll('.menu-back-btn');
    this.closeBtns = globalNavElement.querySelectorAll('.menu-close-btn');
    this.dropdownBtns = globalNavElement.querySelectorAll('#main-menu > li > button');
    this.searchIcon = this.searchMenuButton.querySelector('uxt-icon');
    this.icon = this.menuButton.querySelector('uxt-icon');
    // eslint-disable-next-line no-undef
    this.searchOffcanvasBs = bootstrap.Offcanvas.getInstance(this.searchOffcanvas);
    // eslint-disable-next-line no-undef
    this.mainMenuOffcanvasBs = bootstrap.Offcanvas.getInstance(this.mainNavOffCanvas);
    this.initializeEventListeners();
  }

  initializeEventListeners() {
    this.mainNavOffCanvas.addEventListener('show.bs.offcanvas', this.handleMainNavShow.bind(this));
    this.mainNavOffCanvas.addEventListener('hide.bs.offcanvas', this.handleMainNavHide.bind(this));
    this.searchOffcanvas.addEventListener('show.bs.offcanvas', this.handleSearchOffcanvasShow.bind(this));
    this.searchOffcanvas.addEventListener('shown.bs.offcanvas', () => {
      this.focusOnFirstActionableElement(this.searchOffcanvas);
    });
    this.searchOffcanvas.addEventListener('hide.bs.offcanvas', this.handleSearchOffcanvasHide.bind(this));
    this.searchOffcanvasCloseBtn.addEventListener('click', () => {
      this.searchOffcanvasBs.hide();
    });
    this.backdrop.addEventListener('click', this.handleBackdropClick.bind(this));
    this.level3Menus.forEach((menu) => {
      const menuButton = menu.previousElementSibling;
      const parentUl = menuButton.closest('ul');
      this.level2MenuButtons.push(menuButton);
      menuButton.addEventListener('click', () => this.handleLevel2MenuButtonClick(menuButton, menu, parentUl));
      this.setupMenuFilter(menu);
    });
    this.backBtns.forEach((btn) => {
      btn.addEventListener('click', () => this.handleBackButtonClick(btn));
    });
    this.closeBtns.forEach((btn) => {
      btn.addEventListener('click', async () => {
        this.handleBackdropClick(); //for desktop
        if (this.mainMenuOffcanvasBs) this.mainMenuOffcanvasBs.hide(); //for mobile
      });
    });
    this.dropdownBtns.forEach((btn) => {
      btn.addEventListener('click', () => this.handleDropDownButtonClick(btn));
    });
    this.searchLearningLevelDropdownChoices.forEach((choice) => {
      choice.addEventListener('click', () => {
        this.searchLearningLevelDropdownButton.textContent = choice.textContent;
      });
    });
  }

  handleMainNavShow() {
    this.menuButton.setAttribute('aria-label', this.ARIA_LABEL_CLOSE_MENU);
    // eslint-disable-next-line no-undef
    this.mainMenuOffcanvasBs = bootstrap.Offcanvas.getInstance(this.mainNavOffCanvas);
    this.sendFilterInfo();
    this.focusOnFirstActionableElement(this.mainNavOffCanvas);
  }

  handleMainNavHide() {
    this.menuButton.setAttribute('aria-label', this.ARIA_LABEL_OPEN_MENU);
    this.hideSubMenus();
    this.sendFilterInfo();
  }

  handleSearchOffcanvasShow() {
    this.hideSubMenus();
    this.sendFilterInfo();
    this.dropdownBtns.forEach((btn) => {
      btn.classList.remove('active');
    });
    this.backdrop.classList.remove('show');
    // eslint-disable-next-line no-undef
    this.searchOffcanvasBs = bootstrap.Offcanvas.getInstance(this.searchOffcanvas);
    this.searchMenuButton.setAttribute('aria-label', this.ARIA_LABEL_CLOSE_SEARCH);
  }

  handleSearchOffcanvasHide() {
    this.searchMenuButton.setAttribute('aria-label', this.ARIA_LABEL_OPEN_SEARCH);
    this.searchMenuButton.focus();
  }

  handleBackdropClick() {
    this.hideSubMenus();
    this.removeActiveClasses();
    this.toggleMenuBackdrop();
    this.sendFilterInfo();
  }

  handleDropDownButtonClick(btn) {
    this.sendFilterInfo();
    const menu = btn.nextElementSibling;
    if (!menu.classList.contains('show')) {
      if (this.searchOffcanvasBs) this.searchOffcanvasBs.hide();
      this.hideSubMenus();
      this.removeActiveClasses();
      btn.classList.add('active');
      this.backdrop.classList.add('show');
      menu.classList.add('show');
      this.focusOnFirstActionableElement(menu);
    } else {
      this.hideSubMenus();
      menu.classList.remove('show');
      this.backdrop.classList.remove('show');
      btn.classList.remove('active');
    }
  }

  async handleBackButtonClick(btn) {
    const menuId = btn.dataset.currentMenu;
    const menu = btn.closest(`div#${menuId}`);
    const parentUl = menu.previousElementSibling.closest('ul');
    this.sendFilterInfo();
    menu.classList.remove('show');
    menu.previousElementSibling.setAttribute('aria-expanded', 'false');
    menu.previousElementSibling.classList.remove('active');
    if (parentUl.id !== 'main-menu') {
      await this.addClassesAfterMenuCloses(menu, parentUl, 200);
    }
    this.focusOnFirstActionableElement(menu);
  }

  async handleCloseButtonClick() {
    this.hideSubMenus();
    this.removeActiveClasses();
    this.toggleMenuBackdrop();
    this.sendFilterInfo();
  }

  async handleLevel2MenuButtonClick(menuButton, menu, parentUl) {
    this.sendFilterInfo();
    if (!menu.classList.contains('show')) {
      this.hideAllLevel3Menus();
      parentUl.classList.remove('overflow-scroll');
      menu.classList.remove('d-none');
      const navHeight = window.innerWidth > 1200 ? this.mainNavHeightXl : this.mainNavHeight;
      menu.style.top = `-${menuButton.getBoundingClientRect().top - navHeight}px`;
      menu.classList.add('show');
      this.focusOnFirstActionableElement(menu);
      menuButton.setAttribute('aria-expanded', 'true');
      this.level2MenuButtons.forEach((btn) => {
        btn.classList.remove('active');
      });
      menuButton.classList.add('active');
    } else {
      menu.classList.remove('show');
      menuButton.classList.remove('active');
      menu.previousElementSibling.setAttribute('aria-expanded', 'false');
      await this.addClassesAfterMenuCloses(menu, parentUl, 200);
    }
  }

  async addClassesAfterMenuCloses(menu, parentUl, delayTime) {
    await this.delay(delayTime);
    menu.classList.add('d-none');
    parentUl.classList.add('overflow-scroll');
  }

  delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  debounce(func, delay) {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }

  sendFilterInfo() {
    if (this.hasFilterData(this.globalNavElement)) {
      const filterData = this.getFilterData(this.globalNavElement);
      this.clearFilterData(this.globalNavElement);
      //TODO: this will be sent to datalayer in a future PR
      console.log(filterData);
    }
  }

  hasFilterData(el) {
    return (
      el.hasAttribute('data-filter-menu-id') &&
      el.hasAttribute('data-filter-result-count') &&
      el.hasAttribute('data-filter-text')
    );
  }

  clearFilterData(el) {
    el.removeAttribute('data-filter-menu-id');
    el.removeAttribute('data-filter-result-count');
    el.removeAttribute('data-filter-text');
  }

  getFilterData(el) {
    return {
      menuId: el.getAttribute('data-filter-menu-id'),
      resultCount: el.getAttribute('data-filter-result-count'),
      filterText: el.getAttribute('data-filter-text'),
    };
  }

  hideSubMenus() {
    this.hideAllLevel3Menus();
    this.level2Menus.forEach((menu) => {
      menu.classList.remove('show');
      menu.previousElementSibling.setAttribute('aria-expanded', 'false');
    });
    this.level2MenuButtons.forEach((btn) => btn.classList.remove('active'));
  }

  hideAllLevel3Menus() {
    this.level3Menus.forEach((menu) => {
      if (menu.classList.contains('show')) {
        menu.classList.remove('show');
        menu.previousElementSibling.setAttribute('aria-expanded', 'false');
        this.clearMenuFilter(menu);
        setTimeout(() => {
          menu.classList.add('d-none');
        }, 200);
      }
    });
  }

  removeActiveClasses() {
    this.dropdownBtns.forEach((btn) => btn.classList.remove('active'));
  }

  focusOnFirstActionableElement(menu) {
    const formInputElement = menu.querySelector('input');
    if (formInputElement) {
      formInputElement.focus();
    } else {
      const firstActionableElement = menu.querySelector('.menu-close-btn');
      firstActionableElement.focus();
    }
  }

  toggleMenuBackdrop() {
    if (this.backdrop.classList.contains('show')) {
      this.backdrop.classList.remove('show');
    } else {
      this.backdrop.classList.add('show');
    }
  }

  setupMenuFilter(menu) {
    const filterInput = menu.querySelector('input[type="text"]');
    const numResultsMsg = menu.querySelector('.number-of-results');
    const menuItems = menu.querySelectorAll('[role="menuitem"]');
    const filterDiv = menu.querySelector('.menu-filter');
    const menuUl = menu.querySelector('ul');
    const filterClearBtn = filterDiv.querySelector('button');
    const debouncedFilterMenuItems = this.debounce(
      (event) => this.filterMenuItems(event, menu, filterInput, menuItems, numResultsMsg),
      250
    );
    const debouncedScrollHandler = this.debounce(() => this.handleScroll(menuUl, filterDiv), 250);

    filterInput.addEventListener('keyup', debouncedFilterMenuItems);
    menuUl.addEventListener('scroll', debouncedScrollHandler);
    filterClearBtn.addEventListener('click', () => {
      this.clearMenuFilter(menu);
      filterInput.focus();
    });
  }

  filterMenuItems(event, menu, filterInput, menuItems, numResultsMsg) {
    if (event.key === 'Escape') {
      filterInput.value = '';
      this.sendFilterInfo();
    } else if (event.key === 'Backspace' && filterInput.value === '') {
      this.sendFilterInfo();
    }

    const filter = filterInput.value.toLowerCase().trim();
    const filterClearBtn = menu.querySelector('.menu-filter button');
    if (filter.length > 0) {
      filterClearBtn.classList.remove('d-none');
    } else {
      filterClearBtn.classList.add('d-none');
    }
    menuItems.forEach((item) => {
      const text = item.textContent.toLowerCase();
      item.parentElement.style.display = text.includes(filter) ? 'block' : 'none';
    });

    const results = this.updateResultCount(menuItems, numResultsMsg);
    this.globalNavElement.setAttribute('data-filter-menu-id', menu.id);
    this.globalNavElement.setAttribute('data-filter-result-count', results);
    this.globalNavElement.setAttribute('data-filter-text', filter);
  }

  clearMenuFilter(menu) {
    const filterInput = menu.querySelector('input[type="text"]');
    const numResultsMsg = menu.querySelector('.number-of-results');
    const menuItems = menu.querySelectorAll('[role="menuitem"]');
    const filterClearBtn = menu.querySelector('.menu-filter button');
    filterInput.value = '';
    filterClearBtn.classList.add('d-none');
    menuItems.forEach((item) => {
      item.parentElement.style.display = 'block';
    });
    this.updateResultCount(menuItems, numResultsMsg);
  }

  updateResultCount(menuItems, numResultsMsg) {
    const resultCount = Array.from(menuItems).filter((item) => item.parentElement.style.display !== 'none').length;
    numResultsMsg.textContent = `Showing ${resultCount} result${resultCount !== 1 ? 's' : ''}${
      resultCount === 0 ? ', clear text to see all results.' : ''
    }`;
    return resultCount;
  }

  handleScroll(menuUl, filterDiv) {
    if (menuUl.scrollTop > 0) {
      filterDiv.classList.add('shadow-sm');
    } else {
      filterDiv.classList.remove('shadow-sm');
    }
  }
}
